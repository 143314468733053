import loadable from '@loadable/component'
import { graphql, useStaticQuery } from 'gatsby'
import React, { useMemo } from 'react'
import { AllContentfulLotQuery } from '../../../types/gatsby-graphql'
import { FilterComponent } from '../../components/FilterComponent/FilterComponent'
import { NFTCard } from '../../components/NFTCard/NFTCard'
import { SharedLayout } from '../../components/SharedLayout/SharedLayout'
import { enricheLotWithAuction, getAuctionsIds } from '../../utils/auction'
import * as styles from './Artworks.module.scss'

const Artworks = () => {
  const data: AllContentfulLotQuery = useStaticQuery(graphql`
      query AllContentfulLot {
          allContentfulLot {
              edges {
                  node {
                      ended
                      lotId
                      contentful_id
                      artist {
                          ... on ContentfulArtistSignup {
                              firstName
                              middleName
                              lastName
                              username
                              contentful_id
                          }
                      }
                      auction {
                          ...lotAuctionFragment
                      }
                  }
              }
          }
      }
  `)
  const LoadableGalleryMonitor = loadable(
    () => import('../../components/GalleryMonitor/GalleryMonitor')
  )

  const auctions = useMemo(() => {
    const lots = data?.allContentfulLot.edges.filter(
      item => item.node.auction !== null
    )
    return getAuctionsIds(lots)
  }, [data])

  return (
    <SharedLayout
      title="Artworks"
      blueCenter={false}
      body={
        <>
          <div className={styles.artworksContainer}>
            <FilterComponent/>
            <LoadableGalleryMonitor
              auctions={auctions.filter(String)}
              render={({ result }: any) => {
                const enrichedData = enricheLotWithAuction(
                  data?.allContentfulLot.edges || [],
                  result?.auctions || []
                )

                return (
                  <div className={styles.artworksNFTList}>
                    {enrichedData.map((item: any) => {
                      const name = [
                        item?.lot?.artist?.firstName,
                        item?.lot?.artist?.middleName,
                        item?.lot?.artist?.lastName,
                      ]
                        .join(' ')
                        .trimEnd()

                      if ( !item?.lot?.auction ) {
                        return null
                      }

                      return (
                        <NFTCard
                          title={item?.lot?.auction?.mint?.name!}
                          assetType={
                            item?.lot?.auction?.mint?.mediaAsset?.media
                              ?.mimeType!
                          }
                          imgUrl={
                            item?.lot?.auction?.mint?.mediaAsset?.media?.file
                              ?.url!
                          }
                          price={item?.auction?.reservePriceInETH!}
                          by={name!}
                          linkTo={`/artwork/${item?.lot?.contentful_id}`}
                        />
                      )
                    })}
                  </div>
                )
              }}
            />
          </div>
        </>
      }
    />
  )
}

export default Artworks
